import { helpers } from 'vuelidate/lib/validators';
import dayjs from 'dayjs';

export class Personnummer {
  constructor() {
    this.success = false;
    this.valid = false;
    this.sex = 'U';
    this.year = '0000';
    this.month = '00';
    this.day = '00';
    this.last = '0000';
    this.formatted = '00000000-0000';
    this.samordningsnummer = false;
  }
};

export const validatePersonnr = personnr => {
  return validate_pers_number(personnr);
};

export default validatePersonnr;

// WARNING: if sending undefined it will return true
export const validate_personnr = personnr => {
  return !helpers.req(personnr) || validate_pers_number(personnr).valid;
};

export const over_age = fromAge => personnr => {
  return !helpers.req(personnr) || validate_pers_number(personnr).age >= fromAge;
}

export function validate_pers_number(pers_number) {
  if (pers_number === undefined || pers_number === null) {
    const ret = new Personnummer();
    ret.valid = false;
    ret.success = false;
    return ret;
  }

  const str_pers_number = pers_number + '';

  const test = parse_pers_number(str_pers_number);

  const current_year = new Date().getFullYear().toString(10);

  const result = new Personnummer();

  if (!test.success) {
    return result;
  }

  if (test.year > current_year || test.year < '1900') {
    return result;
  }


  let samordningsnummer = false;

  if (parseInt(test.day) >= 60) {
    // samordningsnummer

    samordningsnummer = true;

    if (test.month > '12') {
      return result;
    }
  }
  else {

    const ts = Date.parse(test.year + '-' + test.month + '-' + test.day);

    if (isNaN(ts)) {
      return result;
    }

  }

  let ssn_test = false;
  const test_char = test.last.substr(0, 1).toUpperCase();

  if ("ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ".indexOf(test_char) >= 0) {
    ssn_test = true;
  }
  else if (test.last === '' || test.last === '0000' || test.last === ' ') {
    ssn_test = false;
  }
  else {
    ssn_test = luhn_check(test.year + test.month + test.day + test.last);
  }

  let sex = 'U';

  if (test.last !== '0000' && ssn_test) {
    if ('0,2,4,6,8'.indexOf(test.last.substr(2, 1)) !== -1) {
      sex = 'F';
    }
    else {
      sex = 'M';
    }
  }

  result.day = test.day;
  result.formatted = test.year + test.month + test.day + '-' + test.last;
  result.month = test.month;
  result.success = true;
  result.valid = ssn_test;
  result.last = test.last;
  result.sex = sex;
  result.year = test.year;

  if (samordningsnummer) {
    // pad with 0
    const d = (parseInt(test.day) - 60);
    result.day = d < 10 ? '0' + d : d+'';
  }

  result.birthdate = dayjs(test.year + '-' + test.month + '-' + test.day).format('YYYY-MM-DD');

  return result;
}


function luhn_check(sPNum) {

  const numbers = sPNum.match(/^(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)$/);
  let checkSum = 0;

  if (numbers === null) { return false; }

  let n;

  for (let i = 3; i <= 12; i++) {
    n = parseInt(numbers[i]);
    if (i % 2 === 0) {
      checkSum += n;
    } else {
      checkSum += (n * 2) % 9 + Math.floor(n / 9) * 9;
    }
  }

  if (checkSum % 10 === 0) { return true; }

  return false;
}


function parse_pers_number(pers_number_string) {
  if (pers_number_string === null || pers_number_string.length < 5) {
    return { success: false, year: '', month: '', day: '', last: '', birthdate: '' };
  }

  let pers_number = '';

  for (let i = 0; i < pers_number_string.length; ++i) {
    const c = pers_number_string.substr(i, 1).toUpperCase();

    if ('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZÅÄÖ'.indexOf(c) !== -1) {
      pers_number += c;
    }
  }


  if (pers_number.length === 8 || pers_number.length === 12) {
    const year = pers_number.substring(0, 2);

    if (year !== '20' && year !== '19') {
      return { success: false, year: '', month: '', day: '', last: '', birthdate: '' };
    }

    if (pers_number.length === 12) { //192003211414
      let ret = { success: true, year: pers_number.substr(0, 4), month: pers_number.substr(4, 2), day: pers_number.substr(6, 2), last: pers_number.substr(8, 4) };

      ret.birthdate = ret.year + '-' + ret.month + '-' + ret.day;

      return ret;
    }
    else if (pers_number.length >= 8) { // 19200321...
      let ret = { success: true, year: pers_number.substr(0, 4), month: pers_number.substr(4, 2), day: pers_number.substr(6, 2), last: '0000' };

      ret.birthdate = ret.year + '-' + ret.month + '-' + ret.day;

      return ret;
    }
  }


  return { success: false, year: '', month: '', day: '', last: '', birthdate: '' };
}
