<template>
  <div>
    <div class="button-container">
      <a
        v-if="!disabled"
        href="#"
        :class="[...styleClass, 'm-button']"
        @click.prevent="clicked"
        >{{ title }}<i v-if="right_icon" :class="['menu-icon', 'flaticon2-down']" style="margin-left: 8px; font-size: 0.8em;"></i></a
      >
      <div v-else :class="[...styleClass, 'disabled-button']">
        {{ title }}<i v-if="right_icon" :class="['menu-icon', 'flaticon2-down']" style="margin-left: 8px; font-size: 0.8em;"></i>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: 'MemlistButton',
  components: {

  },

  props: {
    size: {
      type: String,
      default: 'normal'
    },
    right_icon: {
      type: String,
      default: undefined
    },
    left_icon: {
      type: String,
      default: undefined
    },
    variation: {
      type: String,
      default: 'normal'
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },

  emits: ['clicked'],
  data() {
    return {
      hover: false,
      show_dropdown: false
    };
  },
  computed: {
    styleClass() {

      const classes = [];

      if (this.variation === 'secondary') {
        classes.push('m-secondary');
      }
      
      if (this.variation === 'normal') {
        classes.push('m-primary');
      }

      if (this.size === 'normal') {
        classes.push('s-normal');
      }

      if (this.size === 'small') {
        classes.push('s-small');
      }

      if (this.size === 'large') {
        classes.push('s-large');
      }

      if (this.size === 'xl') {
        classes.push('s-xl');
      }

      return classes;
    }
  },
  async mounted() {
    // Close the dropdown if the user clicks outside of it
  },
  
  watch: {
    
  },
  methods: {

    clicked() {
      this.$emit('clicked');
    },

  }
};
</script>

<style scoped>

  .m-button i {
    color: #3699FF;
  }

  .disabled-button i {
    color: #333333;
  }

  .s-large {
    font-size: 28px;
    padding: 12px 22px !important;
  }

  .m-button {
    border-radius: 4px;
    border: 1px solid #3699FF; /* Added border color */
    padding: 8px 12px;
    cursor: pointer; /* Moved cursor pointer here */
    color: #3699FF;
    background-color: white;
    transition: background-color 0.2s; /* Smooth transition */

    display: inline-block;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.42rem;
    -webkit-transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
  }

  .m-button:hover {
    background-color: #dfefff; /* Slight contrast on hover */
    color: #444444;
  }
  
  .disabled-button {
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer; /* Moved cursor pointer here */
    transition: background-color 0.2s; /* Smooth transition */
    background-color: #dfefff;
    color: #333333;
    border: 1px solid #333333;
  }

  .m-primary {
    background-color: #3699FF;
    color: white;
  }

  .m-secondary:hover {
    background-color: #dfefff;
    color: #444444;
  }

  /* Container for the dropdown */
  .button-container {
    
  }

</style>