<!-- A custom datepicker wrapper -->
<!-- We use this so that we can easily switch in case it deprecates in Vue3 -->

<!--

  <memlist-time-picker
    v-model="local_item.time"
  >
  </memlist-time-picker>
  computed: {
    state_vtdtErr() {
      if (!this.v$.local_item.vtdt.$dirty) {
        return null;
      }

      return dayjs(this.$v.local_item.vtdt).isValid();
    },
  }
-->
<template>
  <div>
    <b-modal
      ref="pick-time-popup"
      hide-footer
      @cancel="time_changed"
    >
      <b-row>
        <b-col cols="6">
          <b-form-select
            :options="hour_options"
            v-model="hour_selected"
          />

        </b-col>
        <b-col cols="6">
          <b-form-select
            :options="minute_options"
            v-model="minute_selected"
          />
        </b-col>
      </b-row>
      <b-row class="mt-6">
        <b-col cols="12">
          <RightModalSaveAndCloseButtons
            :text="$t('COMMON.SAVE')"
            :spin="true"
            @clicked="on_submit_time_modal"
            @close="on_cancel"
            ref="saveButton"
          />
        </b-col>
      </b-row>

    </b-modal>

    <div class="memlist-time-picker">
      <b-form-input
        :class="state_class"
        style="max-width: 210px;"
        v-model="local_value"
        :disabled="disabled"
        @click="input_clicked"
      ></b-form-input>
    </div>
  </div>

</template>
<script>

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';
import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';

export default {
  name: 'memlist-time-picker',
  props: ['value','disabled'],
  mixins: [ toasts ],
  emits: ['input'],
  components: {
    RightModalSaveAndCloseButtons
  },

  watch: {
    value: {
      deep: true,
      handler(val) {

        this.local_value = val;
        this.time_changed();
      }
    },
    local_value: {
      deep: true,
      handler(val) {
        this.time_changed();
        this.$emit('input', this.local_value);
      }
    }
  },

  computed: {
    state_class() {
      return '';
    },

    hour_options() {
      const hours = [];

      for (let i = 0; i < 24; ++i) {
        hours.push({ value: i, text: this.pad_0_str(i+'') });
      }

      return hours;
    },

    minute_options() {
      const minutes = [];

      for (let i = 0; i < 60; ++i) {
        minutes.push({ value: i, text: this.pad_0_str(i+'') });
      }

      return minutes;
    }
  },

  mounted() {

    if (this.value) {
      this.local_value = this.value;
    }

  },

  methods: {
    hide() {
      this.$refs['pick-time-popup'].hide();
    },
    show() {
      this.$refs['pick-time-popup'].show();
    },
    on_cancel() {
      this.time_changed();
      this.hide()
    },
    input_clicked() {
      this.show()
    },
    on_submit_time_modal() {
      this.local_value = this.hour_selected + ':' + this.minute_selected;
      this.time_changed();

      this.$refs['saveButton'].stop();
      this.hide()
    },
    time_changed() {
      if (this.local_value === null || this.local_value === '') {
        return this.local_value = '00:00';
      }

      if (this.local_value.indexOf(':') === -1) {
        this.local_state = false;
        return;
      }

      const parts = this.local_value.split(':');

      if (parts.length !== 2) {
        this.local_state = false;
        return;
      }

      const hour = parseInt(parts[0]);
      const minute = parseInt(parts[1]);

      if (isNaN(hour)) {
        this.local_state = false;
        return;
      }

      if (isNaN(minute)) {
        this.local_state = false;
        return;
      }

      if (hour < 0 || hour > 23) {
        this.local_state = false;
        return;
      }

      if (minute < 0 || minute > 59) {
        this.local_state = false;
        return;
      }

      this.hour_selected = hour;
      this.minute_selected = minute;

      this.local_state = true;

      this.local_value = this.pad_0_str(hour+'') + ':' + this.pad_0_str(minute+'');
    },

    pad_0_str(str) {
      if (str.length === 1) {
        return '0' + str;
      }

      return str;
    },
  },

  data() {
    return {
      hour_selected: 0,
      minute_selected: 0,

      local_state: null,
      local_value: null,
    };
  }
};
</script>

<style lang="css" scoped>
:deep .success input {
  border: 1px solid #1BC5BD;
}

:deep .failure input {
  border: 1px solid #fd397a;
}

:deep input {
  border-color: #ccc;
  cursor: pointer;
}
</style>
