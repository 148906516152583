<!-- A custom datepicker wrapper -->
<!-- We use this so that we can easily switch in case it deprecates in Vue3 -->

<!--

  <memlist-date-picker
    v-model="local_item.vtdt"
    :state="state_vtdt"
  >
  </memlist-date-picker>
  computed: {
    state_vtdt() {
      if (!this.v$.local_item.vtdt.$dirty) {
        return null;
      }

      return dayjs(this.$v.local_item.vtdt).isValid();
    },
  }
-->
<template>

  <div class="memlist-date-picker">
    <date-picker
      :class="state_class"
      :disabled="disabled"
      :placeholder="placeholder"
      :format="format"
      v-model="local_value"
      valueType="format"
      @change="on_change"
    >
    </date-picker>
  </div>

</template>
<script>

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import dayjs from 'dayjs';

export default {

  name: 'memlist-date-picker',

  props: ['value','state','disabled', 'placeholder', 'format'],

  mixins: [ toasts ],

  emits: ['input', 'change'],

  components: {
    DatePicker
  },

  watch: {
    value: {
      deep: true,
      handler(val) {

        this.local_value = val;

        if (this.local_value !== null) {
          this.local_state = dayjs(this.local_value).isValid();
        }
      }
    },
    local_value: {
      deep: true,
      handler(val) {
        this.$emit('input', this.local_value);
      }
    }
  },

  computed: {
    state_class() {
      if (this.state === null || this.state === undefined) { return ''; }

      if (this.state) { return 'success'; }

      if (this.local_state !== null) {
        return this.local_state ? 'success' : 'failure';
      }

      return 'failure';
    }
  },

  mounted() {

    if (this.value) {
      this.local_value = this.value;
    }

  },

  methods: {
    valid_date(date) {
      return dayjs(date).isValid()
    },
    on_change() {
      this.$emit('change');
    },
  },


  data() {
    return {

      local_state: null,

      local_value: null

    };
  }
};
</script>

<style lang="css" scoped>
:deep .success input {
  border: 1px solid #1BC5BD;
}

:deep .failure input {
  border: 1px solid #fd397a;
}

:deep .mx-input {
  height: calc(1.5em + 1.3rem + 1px);
  cursor: pointer;
}
</style>
