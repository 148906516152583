import axios from 'axios';
import store from './store';
import dayjs from 'dayjs';
import { parseJwt } from './jwt.service';

import { PURGE_AUTH, SET_USER_ACCESS_TOKEN, SET_USER_REFRESH_TOKEN } from './store/auth.module';
import { MEMBER_PURGE_AUTH, MEMBER_SET_ACCESS_TOKEN, MEMBER_SET_REFRESH_TOKEN } from './store/member.auth.module';

let is_refreshing = false;
let failed_queue = [];

const processQueue = (error, token = null) => {
  failed_queue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failed_queue = [];
};


axios.interceptors.request.use(async request => {
  let user_token = store.getters.user_access_token;
  let user_refresh_token = store.getters.user_refresh_token;

  if (user_refresh_token && user_refresh_token.length > 16) {
    const parsed = parseJwt(user_refresh_token);
    const cd = dayjs.unix(parsed.exp);
    const now = dayjs();

    if (cd < now) {
      console.log('>> System initiated purge auth: user refresh token has expired', cd, now, user_refresh_token);

      store.commit(PURGE_AUTH);
    }
  }
  
  if (user_token && user_token.length > 16) {
    request.headers.common['X-Access-Token'] = user_token;
  }

  const member_access_token = store.getters.member_access_token;
  const member_refresh_token = store.getters.member_refresh_token;

  if (member_refresh_token && member_refresh_token.length > 16) {
    const parsed = parseJwt(member_refresh_token);
    const cd = dayjs.unix(parsed.exp);
    const now = dayjs();

    if (cd < now) {
      console.log('member refresh token has expired');
      store.commit(MEMBER_PURGE_AUTH);
    }
  }

  if (member_access_token && member_access_token.length > 16) {
    request.headers.common['X-Member-Token'] = member_access_token;
  }

  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {

    const originalRequest = error.config;

    if ((error.response.status === 422 || error.response.status === 401) && error.response.data.context === 'user' && !originalRequest._retry) {
      // if we are already refreshing, wait for the new token
      if (is_refreshing) {
        // add the request to the queue so that we can do it later while refreshing
        return new Promise(function(resolve, reject) {
          failed_queue.push({ resolve, reject });
        })
        .then((token) => {

          originalRequest.headers['X-Access-Token'] = token;

          return axios(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
      }
    

      originalRequest._retry = true;
      is_refreshing = true;

      return new Promise((resolve, reject) => {
        axios.post('/user/refresh-token', {
          'refresh_token': store.getters.user_refresh_token  // replace getRefreshToken with actual implementation
        })
        .then(res => {

          if (res.status !== 201) { 
            console.error('unable to refresh token');
            
            store.commit(PURGE_AUTH);

            reject(error);
            return;
          }

          const data = res.data;

          console.log('token refreshed', data.token, data.refresh_token);

          store.dispatch(SET_USER_ACCESS_TOKEN, data.token);
          store.dispatch(SET_USER_REFRESH_TOKEN, data.refresh_token)

          originalRequest.headers['X-Access-Token'] = data.token;

          processQueue(null, data.token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .then(() => {
          is_refreshing = false;
        });
      });
    }

    if ((error.response.status === 422 || error.response.status === 401) && error.response.data.context === 'member' && !originalRequest._retry) {
      // if we are already refreshing, wait for the new token
      if (is_refreshing) {
        // add the request to the queue so that we can do it later while refreshing
        return new Promise(function(resolve, reject) {
          failed_queue.push({ resolve, reject });
        })
        .then((token) => {

          originalRequest.headers['X-Member-Token'] = token;

          return axios(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
      }
    

      originalRequest._retry = true;
      is_refreshing = true;

      return new Promise((resolve, reject) => {
        axios.post('/member/refresh-token', {
          'refresh_token': store.getters.member_refresh_token  // replace getRefreshToken with actual implementation
        })
        .then(res => {

          if (res.status !== 201) { 
            console.error('unable to refresh token');
            
            store.commit(MEMBER_PURGE_AUTH);

            reject(error);
            return;
          }

          const data = res.data;

          store.dispatch(MEMBER_SET_ACCESS_TOKEN, data.token);
          store.dispatch(MEMBER_SET_REFRESH_TOKEN, data.refresh_token)

          originalRequest.headers['X-Member-Token'] = data.token;

          processQueue(null, data.token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .then(() => {
          is_refreshing = false;
        });
      });
    }

    return error.response;
  }
);
