<template>
  <div>
    <div class="dropdown">
      <a
        href="#"
        :class="[styleClass, 'm-button']"
        @click.prevent="toggle_dropdown"
        >{{$t('ADVANCED_SEARCH.SAVE_OPTIONS')}}<i v-if="right_icon" :class="['menu-icon', 'flaticon2-down']" style="margin-left: 8px; font-size: 0.8em;"></i></a
      >
      <ul @mouseover="hover = true" @mouseleave="hover = false" v-if="show_dropdown" class="dropdown-content">
        
        <li
          v-for="(option, index) in options"
          :key="option.value || index"
          @click="select_option(option)"
        >
          {{ option.text }}
        </li>
        
      </ul>
    </div>
  </div>
</template>


<script>

export default {
  name: 'MemlistDropdown',
  components: {

  },

  props: {
    right_icon: {
      type: String,
      default: undefined
    },
    left_icon: {
      type: String,
      default: undefined
    },
    options: {
      type: Array,
      default: []
    },
    variation: {
      type: String,
      default: 'normal'
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },

  emits: ['selected'],
  data() {
    return {
      hover: false,
      show_dropdown: false
    };
  },
  computed: {
    styleClass() {
      return this.variation === 'secondary' ? 'm-secondary' : 'm-primary';
    }
  },
  async mounted() {
    // Close the dropdown if the user clicks outside of it
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener('click', this.handle_click_outside);
  },
  watch: {
    
  },
  methods: {

    select_option(id) {
      this.$emit('selected', id);
      this.show_dropdown = false;
    },

    handle_click_outside() {
      if (!this.hover) {
        this.show_dropdown = false;

        document.removeEventListener('click', this.handle_click_outside);
      }
    },

    toggle_dropdown() {
      this.show_dropdown = true;

      setTimeout(() => {
        document.addEventListener('click', this.handle_click_outside);
      }, 100)
      
    },
  }
};
</script>

<style scoped>

  i {
    color: #3699FF;
  }

  .m-button {
    border-radius: 4px;
    border: 1px solid #3699FF; /* Added border color */
    padding: 8px 12px;
    cursor: pointer; /* Moved cursor pointer here */
    color: #3699FF;
    background-color: white;
    transition: background-color 0.2s; /* Smooth transition */
  }

  .m-button:hover {
    background-color: #dfefff; /* Slight contrast on hover */
  }

  .m-secondary:hover {
    background-color: #dfefff;
  }


  /* Container for the dropdown */
  .dropdown {
      position: relative;
      display: inline-block;
  }

  /* The popup list (hidden by default) */
  .dropdown-content {
      
      position: absolute;
      background-color: #fff;
      min-width: 160px;
      margin-top: 5px;
      border: 1px solid #ccc;
      z-index: 1;
      padding: 0;
      list-style-type: none;
  }

  /* List items */
  .dropdown-content li {
      padding: 10px;
  }

  /* Highlight and pointer on hover */
  .dropdown-content li:hover {
      background-color: #f1f1f1;
      cursor: pointer;
  }

  /* Show the list when the dropdown is active */
  .dropdown.active .dropdown-content {
      display: block;
  }
</style>