import Cookies from 'js-cookie';

// action types
export const MEMBER_LOGIN = 'memberlogin';
export const MEMBER_LOGOUT = 'memberlogout';
export const MEMBER_SET_COMPANY = 'setMemberCompany';
export const MEMBER_SET_LOGINTOKEN = 'setMemberLoginToken';
export const MEMBER_SET_LOGINLINK = 'setMemberLoginLink';
export const MEMBER_SET_ACCESS_TOKEN = 'setMemberAccessToken';
export const MEMBER_SET_REFRESH_TOKEN = 'setMemberRefreshToken';



// mutation types
export const MEMBER_PURGE_AUTH = 'memberlogOut';
export const MEMBER_SET_AUTH = 'setMember';
export const MEMBER_UPDATE_LOGINTOKEN = 'updateLoginToken'
export const MEMBER_UPDATE_LOGINLINK = 'updateMemberLoginLink'
export const MEMBER_UPDATE_COMPANY = 'updateMemberCompany';
export const MEMBER_UPDATE_ACCESS_TOKEN = 'updateMemberAccessToken';
export const MEMBER_UPDATE_REFRESH_TOKEN = 'updateMemberRefreshToken';


const memberc = localStorage.getItem('member');
const memberCompany = localStorage.getItem('memberCompany');
const memberloginlink = Cookies.get('mloginlink');
const member_refresh_token = Cookies.get('member_refresh_token');
const member_access_token = Cookies.get('member_access_token');

const state = {
  member_access_token: 'undefined' === typeof member_access_token || member_access_token === undefined || member_access_token === 'undefined' ? null : Cookies.get('member_access_token'),
  member_refresh_token: ('undefined' === typeof member_refresh_token || member_refresh_token === undefined || member_refresh_token === 'undefined') ? null : Cookies.get('member_refresh_token'),
  mlogin_link: memberloginlink,
  member: 'undefined' === typeof memberc ? null : JSON.parse(localStorage.getItem('member')),
  memberCompany: (memberCompany === 'undefined' || 'undefined' === typeof memberCompany || !memberCompany) ? null : JSON.parse(memberCompany),
  mLoginToken: Cookies.get('mlogintoken'),
};

function token_expires_at(token) {
  if (token === null || token === undefined) return 0;

  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  
  const expirationTime = decodedToken.exp;

  return expirationTime;
}

function isTokenExpired(token) {
  const decodedToken = JSON.parse(atob(token.split('.')[1]));
  
  const expirationTime = decodedToken.exp * 1000; // convert to milliseconds
  const currentTime = new Date().getTime();
  
  return currentTime >= expirationTime;
}

const getters = {
  member_access_token: state => state.member_access_token,
  member_refresh_token: state => state.member_refresh_token,
  //currentMemberCompany: state => state.membercompany,
  mlogin_link:        state => state.mlogin_link,
  memberCompany:      state => state.memberCompany,
  currentMember:      state => state.member,
  mtoken:             state => state.mtoken,
  mtokenExpireAt:     state => token_expires_at(state.mtoken),
  // check token expired
  isMemAuthenticated:  state => {
    const member_refresh_token_expire_at = token_expires_at(state.member_refresh_token);

    const d1= new Date();
    const d2= new Date(member_refresh_token_expire_at * 1000);

    console.log('expiration check ', d1, d2);

    return d1 < d2;
  },
  memberLoginToken:   state => state.mLoginToken
};

const actions = {
  isMemberAuthenticated({ state }) {
    const member_refresh_token_expire_at = token_expires_at(state.member_refresh_token);
    const currentTime = new Date();

    if (!state.member_refresh_token) {
      return false;
    }

    return currentTime < new Date(member_refresh_token_expire_at * 1000);
  },

  [MEMBER_SET_ACCESS_TOKEN](context, data){
    context.commit(MEMBER_UPDATE_ACCESS_TOKEN, data);
  },
  [MEMBER_SET_REFRESH_TOKEN](context, data) {
    context.commit(MEMBER_UPDATE_REFRESH_TOKEN, data);
  },
  [MEMBER_SET_LOGINLINK](context, data) {
    context.commit(MEMBER_UPDATE_LOGINLINK, data);
  },
  [MEMBER_LOGIN](context, data) {
    context.commit(MEMBER_SET_AUTH, data);
  },
  [MEMBER_LOGOUT](context) {
    context.commit(MEMBER_PURGE_AUTH);
  },
  [MEMBER_SET_COMPANY](state, payload) {
    state.commit(MEMBER_UPDATE_COMPANY, payload);
  },
  [MEMBER_SET_LOGINTOKEN](state, payload) {
    state.commit(MEMBER_UPDATE_LOGINTOKEN, payload);
  },
};

const mutations = {
  [MEMBER_UPDATE_ACCESS_TOKEN](state, data) {
    state.member_access_token = data;

    const member_token_expire_at = token_expires_at(state.member_access_token);

    Cookies.set('member_access_token', state.member_access_token, { expires: new Date(member_token_expire_at * 1000) });
  },
  [MEMBER_UPDATE_REFRESH_TOKEN](state, data) {
    state.member_refresh_token = data;

    const member_refresh_token_expire_at = token_expires_at(state.member_refresh_token);

    Cookies.set('member_refresh_token', state.member_refresh_token, { expires: new Date(member_refresh_token_expire_at * 1000) });
  },

  [MEMBER_UPDATE_LOGINLINK](state, data) {
    state.mlogin_link = data;
    Cookies.set('mloginlink', state.mlogin_link)
  },
  [MEMBER_SET_AUTH](state, data) {
    state.member = data.member;
    state.member_access_token = data.token;
    state.member_refresh_token = data.refresh_token;
    state.memberCompany = data.company;
    state.loggedIn = true;
    
    const member_token_expire_at = token_expires_at(state.member_access_token);
    const member_refresh_token_expire_at = token_expires_at(state.member_refresh_token);

    Cookies.set('member_access_token', state.member_access_token, { expires: new Date(member_token_expire_at * 1000) });
    Cookies.set('member_refresh_token', state.member_refresh_token, { expires: new Date(member_refresh_token_expire_at * 1000) });

    localStorage.setItem('memberCompany', JSON.stringify(state.memberCompany));
    localStorage.setItem('member', JSON.stringify(data.member));
  },
  [MEMBER_PURGE_AUTH](state) {
    state.member = null;
    localStorage.removeItem('member');
    localStorage.removeItem('memberCompany');
    Cookies.remove('member');
    Cookies.remove('member_access_token');
    Cookies.remove('member_refresh_token');
    Cookies.remove('memberCompany');
  },
  [MEMBER_UPDATE_COMPANY](state, data) {
    state.memberCompany = data;
    localStorage.setItem('memberCompany', JSON.stringify(state.memberCompany));
  },
  [MEMBER_UPDATE_LOGINTOKEN](state, data) {
    state.mLoginToken = data;
    Cookies.set('mlogintoken', state.mLoginToken);
  }

};

export default {
  state,
  actions,
  mutations,
  getters
};
