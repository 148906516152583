
export const toasts = {
  data() {
    return {
      
    };
  },
  methods: {
    toastr(type, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 1000,
        appendToast: true,
        variant: type
      });
    },
    showHelpToast(page) {
      // Use a shorter name for `this.$createElement`
      const h = this.$createElement
      // Create a ID with a incremented count
      const id = `my-toast-${this.count++}`

      const $helpLink = h(
        'a', // type
        { class: ['toastr-pointer'], on: { click: () => window.open('https://memlist.se/documentation/' + page, '_blank') } },
        this.$t('COMMON.CLICK_HELP')
      )

      // Create the toast
      this.$bvToast.toast([$helpLink], {
        title: this.$t('COMMON.HELP'),
        id: id,
        noCloseButton: true,
        toaster: 'b-toaster-bottom-right',
        variant: 'info',
        autoHideDelay: 10000,
      })
    },
  }
};
