import {
  SET_COMPANY_ID,
  SET_SID,
  SET_PERIOD_ID,
  SET_PERIODS,
  SET_TITLES,
} from '@/core/services/store/common.module';

import { SET_ACCESS, SET_IS_SUPERADMIN, LOGOUT } from '@/core/services/store/auth.module';
import axios from 'axios';
import store from '@/core/services/store';


export function getAnyPageWithAccess() {
  const currentAccess = store.getters['currentAccess'];

  if ('ml-dashboard' in currentAccess) {
    if (currentAccess['ml-dashboard']) {
      return 'ml-dashboard';
    }
  }

  for (const k in currentAccess) {
    if (currentAccess[k]) {
      return k;
    }
  }

  return null;
}

export function clearCurrentCompanyId() {
  store.dispatch(SET_COMPANY_ID, null);
}

export function getCurrentPeriods() {
  return store.getters['periods'];
}

export function getCurrentPeriod() {
  const periods = store.getters['periods'];

  const period = periods.find(item => item.id === store.getters['currentPeriodId']);

  return period;
}

function is_super_admin() {
  return store.getters['isTHS'];
}

export function hasAccessToPageForCurrentCompany(stripped) {
  
  const currentAccess = store.getters['currentAccess'];
  
  if (is_super_admin()) {
    return true;
  }

  if (stripped in currentAccess) {
    return true;
  }

  return false;
}


export function setCurrentPeriodId(periodId, doReload) {
  store.dispatch(SET_PERIOD_ID, periodId);

  if (doReload) {
    window.location.reload();
  }
}
